<template>
    <div class="screen_main">
        <div class="mob_nav"><MobNav /></div>
        <div class="d-flex h-full">
            <div class="flex-1 position-relative">
                <el-button class="downPDF" type="primary" style="position: absolute;right: 30px;top: 10px;z-index: 999;" @click="downPDF">
                    下载报告
                </el-button>
                <overlay-scrollbars style="width: 100%;height: 100%;" class="el-fade-in">
                    <Report ref="report"/>
                </overlay-scrollbars>
            </div>
            <div class="d-flex a-center portrait_nav screen_nav"><Nav :params="{lesson_id:lesson_id,teacher_id:teacher_id}"/></div>
        </div>
    </div>
</template>
<script>
import Nav from '../../pages/screen/comp/nav.vue'
import Report from '../../pages/report/index.vue'
import MobNav from '@/components/MobileMenu.vue'
export default {
    components:{
        Nav,
        Report,
        MobNav
    },
    data(){
        return{
            lesson_id:'',
            teacher_id:'',
        }
    },
    created(){
        this.teacher_id = this.$route.query.teacher_id || ''
        this.lesson_id = this.$route.query.lesson_id || ''
    },
    methods:{
        downPDF(){
            this.$refs.report.downPdf()
        }
    }
}
</script>
<style scoped>

</style>